





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { getDefaultFuseOptions } from '@/utils'
import { multiSearch } from '@/utils/Common'
import Fuse from 'fuse.js'
import { sectionsStore } from '@/store'

interface SelectorOption {
  value: string | null;
  label: string;
}

@Component({ name: 'TaskSectionSelector' })
export default class TaskSectionSelector extends Vue {
  @Prop({
    type: String,
    default: null,
  }) readonly value!: string | null

  fuseOptions = getDefaultFuseOptions(['label'])

  // bodgy bodge for our z-index values vs quasar default ones
  popupContentStyle: Partial<CSSStyleDeclaration> = { zIndex: '9999' }
  filteredOptions: SelectorOption[] = []

  get placeholder (): string | null {
    return this.computedValue?.value ? null : this.noSectionLabel
  }

  get fuse (): Fuse<SelectorOption> {
    return new Fuse(this.options, this.fuseOptions)
  }

  get noSectionLabel (): string {
    const key = 'common.withoutProject'
    // const options: i18next.TranslationOptions = { postProcess: 'capitalize' }
    return this.$t(key/*, options */).toString()
  }

  get noSectionOption (): SelectorOption {
    return { value: null, label: this.noSectionLabel }
  }

  get options (): SelectorOption[] {
    const existingSectionsOptions: SelectorOption[] = Object
      .values(sectionsStore.state.task)
      .map(s => ({ value: s.uid, label: s.name }))
      .sort((a, b) => a.label > b.label ? 1 : -1)
    existingSectionsOptions.push(this.noSectionOption)
    return existingSectionsOptions
  }

  get computedValue (): SelectorOption | null {
    return !this.value
      ? null
      : this.options.find(o => o.value === this.value) ?? this.noSectionOption
  }

  set computedValue (v) {
    this.$emit('input', v)
  }

  searchFn (text: string): SelectorOption[] {
    return multiSearch((text: string) => this.fuse.search(text), text, 'uid')
  }

  filterFn (userInput: string, update: (callback: () => void) => unknown): void {
    const val = userInput.trim()

    // filter only starting with second character
    update(() => {
      this.filteredOptions = val.length < 2
        ? this.options
        : this.searchFn(val)
    })
  }
}
